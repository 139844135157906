@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Domine:wght@500&family=Libre+Baskerville&display=swap');

::root{
    --primary-blue: #00d8ff;
    --primary-purple: #6a00ff;
}


@layer base{
    *{
        @apply font-[Domine] bg-[#050A30]
    }
    
    h1 {
        @apply text-3xl font-extrabold
    }

    li{
        @apply p-4
    }

    button{
        @apply bg-[#2E8BC0] py-3 px-7 rounded-3xl hover:bg-[#38a6e5]
    }

    p{
        @apply text-lg text-white
    }

    span{
        @apply text-[#2E8BC0]
    }
}